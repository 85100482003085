import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../cash it in logo.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-md navbar-dark font1 navcolor">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" className="" style={{ height: '40px', borderRadius: '3rem' }} />
          <span className="mt-1 position-absolute" style={{ marginLeft: '1rem' }}>Cash-it-in</span>
        </Link>
        
        <button className="navbar-toggler" type="button" onClick={toggleMobileMenu} aria-controls="navbarSupportedContent" aria-expanded={isMobileMenuOpen} aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse justify-content-end ${isMobileMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav mb-lg-0" style={{ marginRight: '6rem', gap: '2rem' }}>
            <li className="nav-item">
              <Link className="nav-link navnavs" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link navnavs" to="/buy-back">Buy-Back</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link navnavs" to="/lay-away">Lay-Away</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link navnavs" to="/selltous">Sell To Us</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link navnavs" to="/Policies">Policies</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

