import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import Caro from '../comps/carolayaway'
import Services from '../comps/serviceslayaway'
import TitleDesc from '../comps/titledesclayaway'
import LocationBanner from '../comps/Locationbanner.js';
import Footer from '../comps/footer.js';



const BuyBack = () => {
    return (
      <div>
       <Caro/>
       <Services/>
       <TitleDesc/>
       <LocationBanner/>
       <Footer/>
      </div>
    );
  };
  
  export default BuyBack;
  