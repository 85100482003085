import React from 'react';
import { Carousel } from 'react-bootstrap';
import ExampleCarouselImage from '../img1.png'; 
import ExampleCarouselImage2 from '../img2.png'; 
import ExampleCarouselImage3 from '../img3.png'; 
import { hexToRgb } from '@mui/material';
import { height } from '@mui/system';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function Caro() {
  return (
    <Carousel>
      <Carousel.Item>
        <img style={{ height: '25rem'}}
          className="d-block w-100"
          src={ExampleCarouselImage}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='font1 carotext' style={{ color: 'white' }}>Top of the range Consoles</h3>
          <p className='fontdesc carotext' style={{ color: 'white' }}>We buy, sell and offer Buy-backs on all modern Consoles</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img style={{ height: '25rem'}}
          className="d-block w-100"
          src={ExampleCarouselImage2}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='font1 carotext' style={{ color: 'white' }}>Right tools for the right job</h3>
          <p className='fontdesc carotext' style={{ color: 'white' }}>We sell power tools so you can get your job done to a good quality standard</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img style={{ height: '25rem'}}
          className="d-block w-100"
          src={ExampleCarouselImage3}
          alt="First slide"
        />
        <Carousel.Caption>
        <h3 className='font1 carotext' style={{ color: 'white' }}>Jewelry</h3>
          <p className='fontdesc carotext' style={{ color: 'white' }}>
          We have all the bling you could want, all at a reasonable price
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Caro;

