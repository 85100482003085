import React from 'react';
import '../index.css';
import '../custom.css';
import poundsvg from '../poundsvg.svg'



const Titledesc = () => {
    return (
        <div className='text-center'>
            <p className='fontdesc fw-bold px-mob-3 mobiletitle' style={{marginTop: '4rem', color: 'red', fontSize: '2.2rem'}}>
                CASH-IT-IN, WHERE SECOND-HAND MEETS GREAT QUALITY

                <div>
                <img src={poundsvg} alt="Logo" className='svg'/>
                </div>

            </p>

            
            <p className='mobilefont fw-medium fontdesc text-center' style={{marginTop: '1rem', paddingLeft: '10%', paddingRight: '10%', fontSize: '1.3rem'}}>
                We offer honest, fair value on items of goods, we also offer a premium buy-back option for when you need quick instant cash but don’t want to say goodbye to your item.
                We strive for quality on all items we sell, second-hand shouldnt scrape on quality, thats what we stand by.
            </p>
            
        </div>
    );
}

export default Titledesc;