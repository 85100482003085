import React from 'react';
import { Carousel } from 'react-bootstrap';
import ExampleCarouselImage from '../buybackcaroimg1.png'; 
import ExampleCarouselImage2 from '../buybackcaroimg2.png'; 
import ExampleCarouselImage3 from '../img3.png'; 
import { hexToRgb } from '@mui/material';
import { height } from '@mui/system';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function Caro() {
  return (
    <Carousel>
      <Carousel.Item>
        <img style={{ height: '25rem'}}
          className="d-block w-100"
          src={ExampleCarouselImage}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='font1 carotext' style={{ color: 'white' }}>Need cash but dont want to say goodbye to your item?</h3>
          <p className='fontdesc carotext' style={{ color: 'white' }}>We offer cash amounts dependant on the value of your item on our premium Buy-Back service</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img style={{ height: '25rem'}}
          className="d-block w-100"
          src={ExampleCarouselImage2}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='font1 carotext' style={{ color: 'white' }}>Your items are safe with us until you return for them</h3>
          <p className='fontdesc carotext' style={{ color: 'white' }}>We put all Buy-Back items in our safe in store to ensure they’re as safe as possible </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Caro;