import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './comps/navbar';
import Caro from './comps/caro.js';
import Home from './pages/home';
import BuyBack from './pages/buy-back page.js';
import Selltous from './pages/selltous.js';
import Policies from './pages/Policies.js';
import LayAway from './pages/layaway.js'
import './index.css';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/buy-back" element={<BuyBack />} />
          <Route path="/lay-away" element={<LayAway />} />
          <Route path="/selltous" element={<Selltous/>} />
          <Route path="/Policies" element={<Policies />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

