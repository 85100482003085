import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import Navbar from '../comps/navbar.js'; 
import Caro from '../comps/caro.js'
import '../index.css'; 
import Titledesc from '../comps/titledesc.js'
import InfoSection from '../comps/services.js';
import LocationBanner from '../comps/Locationbanner.js';
import Footer from '../comps/footer.js';
import Buyback28 from '../comps/28bb.js';









const Home = () => {
  return (
    <div>
      <Caro />
      <Buyback28/>
      <Titledesc/>
      <InfoSection/>
      <LocationBanner/>
      <Footer/>
    </div>
  );
};

export default Home;
