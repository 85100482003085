import React from 'react';
import '../index.css';
import '../custom.css';
import qualitysvg from '../qualsvg.svg'



const buyback28 = () => {
    return (
        <div className='text-center' style={{ marginBottom: '10rem' }}>


            <p className='fontdesc fw-bold px-mob-3 mobiletitle' style={{marginTop: '6rem', color: 'red', fontSize: '2.2rem'}}>
                OUR 28 DAY PREMIMUM BUY BACK SERVICE 

                <div>
                    <img src={qualitysvg} alt="Logo" className='svg' />
                </div>

            </p>

            
            <p className='mobilefont fw-medium fontdesc text-center' style={{marginTop: '1rem', paddingLeft: '10%', paddingRight: '10%', fontSize: '1.3rem'}}>
            Get instant cash against the value of your item without saying goodbye to it forever! Ask about out buy back service and you can be walking out with quick cash!
            </p>
            
        </div>
    );
}

export default buyback28;