import React from 'react';
import '../index.css';
import '../custom.css';



const Titledesc = () => {
    return (
        <div className='text-center'>
            <p className='fontdesc fw-bold px-mob-3 mobiletitle' style={{marginTop: '4rem', color: 'red', fontSize: '1.8rem'}}>
            FAST CASH AT CASH-IT-IN
            </p>

            
            <p className='mobilefont fw-medium fontdesc text-center' style={{marginTop: '3rem', paddingLeft: '10%', paddingRight: '10%', fontSize: '1.3rem'}}>
            We offer for Buy-Backs on most items as long as they’re in good condition and work/serve the purpose they’re intended to, we understand you may come into hard times and need some quick cash, so we will always try to give the maximum amount we can whilst still remaining competitive.
            </p>
            
        </div>
    );
}

export default Titledesc;