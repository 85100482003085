import React from 'react';
import { Carousel } from 'react-bootstrap';
import ExampleCarouselImage from '../sellcaroimg1.png'; 
import ExampleCarouselImage2 from '../Selltouscaroimg2.png'; 
import ExampleCarouselImage3 from '../img3.png'; 
import { hexToRgb } from '@mui/material';
import { height } from '@mui/system';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function Caro() {
  return (
    <Carousel>
      <Carousel.Item>
        <img style={{ height: '25rem'}}
          className="d-block w-100"
          src={ExampleCarouselImage}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='font1 carotext' style={{ color: 'white' }}>We Buy Items Of Value</h3>
          <p className='fontdesc carotext' style={{ color: 'white' }}>We buy items in depending on the item, its condition and how sought after it is</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img style={{ height: '25rem'}}
          className="d-block w-100"
          src={ExampleCarouselImage2}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='font1 carotext' style={{ color: 'white' }}>We Fairly Price Each Item</h3>
          <p className='fontdesc carotext' style={{ color: 'white' }}>We promise to offer the fairest price that works for both us and you as a customer</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Caro;