import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import Navbar from '../comps/navbar.js'; 
import Caro from '../comps/caroselltous.js'
import '../index.css'; 
import InfoSection from '../comps/servicesselltous.js';
import InfoSection2 from '../comps/titledescselltous.js';
import LocationBanner from '../comps/Locationbanner.js';
import Footer from '../comps/footer.js';





const Pricing = () => {
  return (
    <div>
      <Caro/>
      <InfoSection/>
      <InfoSection2/>
      <LocationBanner/>
      <Footer/>
    </div>
  );
};

export default Pricing;
