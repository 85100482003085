import React from 'react';
import { Carousel } from 'react-bootstrap';
import ExampleCarouselImage from '../carolayawayimage1.png'; 
import ExampleCarouselImage2 from '../carolayawayimage2.png'; 
import ExampleCarouselImage3 from '../img3.png'; 
import { hexToRgb } from '@mui/material';
import { height } from '@mui/system';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function Caro() {
  return (
    <Carousel>
      <Carousel.Item>
        <img style={{ height: '25rem'}}
          className="d-block w-100"
          src={ExampleCarouselImage}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='font1 carotext' style={{ color: 'white' }}>Try Our Premimum Lay-Away Service</h3>
          <p className='fontdesc carotext' style={{ color: 'white' }}>We offer 20% deposits on all items we sell with 4 fortnightly payments</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img style={{ height: '25rem'}}
          className="d-block w-100"
          src={ExampleCarouselImage2}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='font1 carotext' style={{ color: 'white' }}>Payments Made Simple</h3>
          <p className='fontdesc carotext' style={{ color: 'white' }}>We allow you to pay off your item in 4 payments made every 2 weeks for the remainder of the items price </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Caro;

