import React from 'react';
import '../index.css';
import '../custom.css';


const PrivPol = () => {
    return (
        <div className='text-center'>
            <p className='fontdesc fw-bold px-mob-3 mobiletitle' style={{marginTop: '4rem', color: 'red', fontSize: '2.2rem'}}>
                - OUR PRIVACY POLICY - 
            </p>

            <div className='mobilefont fw-medium fontdesc text-center' style={{marginTop: '3rem', paddingLeft: '10%', paddingRight: '10%', fontSize: '1.3rem'}}>
                <p>
                    This Privacy Policy outlines how Cash-it-in-Goole ("we", "us", or "our") collects, uses, and discloses your personal information when you visit, use our services, or make a purchase from www.website (the "Site") or otherwise communicate with us (collectively, the "Services"). By using and accessing any of the Services, you agree to the collection, use, and disclosure of your information as described in this Privacy Policy. If you do not agree, please do not use or access any of the Services.
                </p>
                <p>
                    <strong>Changes to This Privacy Policy</strong><br/>
                    We may update this Privacy Policy from time to time for operational, legal, or regulatory reasons. Updates will be posted on the Site with the revised date.
                </p>
                <p>
                    <strong>How We Collect and Use Your Personal Information</strong><br/>
                    We collect personal information from various sources over the past 12 months, depending on your interactions with us.
                </p>
                <p>
                    <strong>Personal Information We Collect</strong><br/>
                    1. Direct Information: Includes name, address, phone number, email, order information, account information, shopping information, and customer support information.<br/>
                    2. Cookies and Usage Data: Includes device information, browser information, network connection, IP address, and interactions with the Services.<br/>
                    3. Third-Party Information: From vendors and service providers like Shopify and payment processors.
                </p>
                <p>
                    <strong>Use of Personal Information</strong><br/>
                    • Providing products and services<br/>
                    • Marketing and advertising<br/>
                    • Security and fraud prevention<br/>
                    • Communication and customer support
                </p>
                <p>
                    <strong>Cookies</strong><br/>
                    We use Cookies to improve our Site and Services, perform analytics, and tailor advertising. You can manage Cookies through browser settings, but this may impact your experience.
                </p>
                <p>
                    <strong>Disclosure of Personal Information</strong><br/>
                    We may disclose your personal information to:<br/>
                    • Vendors and service providers<br/>
                    • Business and marketing partners<br/>
                    • Affiliates within our corporate group<br/>
                    • Legal and regulatory authorities, as required
                </p>
                <p>
                    <strong>User-Generated Content</strong><br/>
                    Content you post publicly may be accessible to others. We are not responsible for the privacy or security of such information.
                </p>
                <p>
                    <strong>Third-Party Websites and Links</strong><br/>
                    Our Site may contain links to third-party websites. We are not responsible for their privacy practices or content. Review their privacy policies for more information.
                </p>
                <p>
                    <strong>Children’s Data</strong><br/>
                    The Services are not intended for children, and we do not knowingly collect personal information from children. Parents can contact us to delete such information.
                </p>
                <p>
                    <strong>Security and Retention</strong><br/>
                    We implement security measures to protect your information but cannot guarantee complete security. We retain personal information as long as needed for our business purposes and legal obligations.
                </p>
                <p>
                    <strong>Your Rights and Choices</strong><br/>
                    Depending on your location, you may have rights such as:<br/>
                    • Access to personal information<br/>
                    • Deletion of personal information<br/>
                    • Correction of inaccurate information<br/>
                    • Data portability<br/>
                    • Opt-out of sale or sharing of information<br/>
                    • Restriction of processing<br/>
                    • Withdrawal of consent<br/>
                    • Appeal of decisions regarding your requests
                </p>
                <p>
                    To exercise these rights, contact us using the details below. We will verify your identity before processing requests.
                </p>
                <p>
                    <strong>Complaints</strong><br/>
                    Contact us with complaints about our processing of your personal information. You may also contact your local data protection authority.
                </p>
                <p>
                    <strong>International Users</strong><br/>
                    We may transfer your personal information outside your country, including to the United States, and will use recognized transfer mechanisms to protect your data.
                </p>
                <p>
                    <strong>Contact Information</strong><br/>
                    For questions or to exercise your rights, contact us at:<br/>
                    • Phone: 01405800727<br/>
                    • Email: <a href="mailto:Sales@cash-it-in-goole.com">Sales@cash-it-in-goole.com</a><br/>
                    • Mail: 88-90 Boothferry Road, Goole, DN14 6AE, East Yorkshire, United Kingdom
                </p>
            </div>
        </div>
    );
}

export default PrivPol;