
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faBagShopping, faLocationDot, faCoins} from '@fortawesome/free-solid-svg-icons';
import { faSterlingSign } from '@fortawesome/free-solid-svg-icons';


const InfoSection = () => {
  return (
    <Container className="text-center my-5">
      <Row style={{ marginTop: '8rem'}}>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faCoins} size="2x" style={{ color: 'red'}}/>
            </div>
            <h3 className="mt-3 font1">What Won't We Buy!</h3>
            <p className='fontdesc'>We buy most items of value, as long as they're in good condition and work as intended then we will give you straight cash for them!</p>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faSterlingSign} size="2x" style={{ color: 'red' }}/>
            </div>
            <h3 className="mt-3 font1">Big Bucks</h3>
            <p className='fontdesc'>We buy the most modern of technology, so don’t be hesitant to bring in that iPhone 15 or that PS5, we offer fair, honest value for your prized goods</p>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faLock} size="2x" style={{ color: 'red' }}/>
            </div>
            <h3 className="mt-3 font1">3 Month Warranty</h3>
            <p className='fontdesc'>We offer a strong 3 month warranty on all items we buy in, so if you ever buy from us, you’re in safe hands!</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default InfoSection;

