import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import Navbar from '../comps/navbar.js'; 
import Caro from '../comps/caro.js'
import '../index.css'; 
import PrivPolicy from '../comps/privpolicy.js'
import LocationBanner from '../comps/Locationbanner.js';
import Footer from '../comps/footer.js';




const PrivP = () => {
  return (
    <div>
      <PrivPolicy/>
      <LocationBanner/>
      <Footer/>
    </div>
  );
};

export default PrivP;
