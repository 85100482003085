import React from 'react';
import '../index.css';
import '../custom.css';



const Titledesc = () => {
    return (
        <div className='text-center'>
            <p className='fontdesc fw-bold px-mob-3 mobiletitle' style={{marginTop: '4rem', color: 'red', fontSize: '1.8rem'}}>
            ONLY AT CASH-IT-IN!
            </p>

            
            <p className='mobilefont fw-medium fontdesc text-center' style={{marginTop: '3rem', paddingLeft: '10%', paddingRight: '10%', fontSize: '1.3rem'}}>
            We offer solid prices for your goods, and we pride ourselves on that, we will always try to match and beat other offered prices, that’s how much we care about offering a solid service you can rely on, see you at Cash-it-in!
            </p>
            
        </div>
    );
}

export default Titledesc;