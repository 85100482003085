
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faBagShopping, faLocationDot, faCalendarDays, faVault} from '@fortawesome/free-solid-svg-icons';
import { faSterlingSign } from '@fortawesome/free-solid-svg-icons';



const InfoSection = () => {
  return (
    <Container className="text-center my-5">
      <Row style={{ marginTop: '8rem'}}>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faCalendarDays} size="2x" style={{ color: 'red'}}/>
            </div>
            <h3 className="mt-3 font1">28 Day Buy-Backs</h3>
            <p className='fontdesc'>We do out buy-backs on a 28 day basis to leave you with plenty of time to come and buy your item back!</p>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faSterlingSign} size="2x" style={{ color: 'red' }}/>
            </div>
            <h3 className="mt-3 font1">Loyalty Scheme</h3>
            <p className='fontdesc'>We offer a Loyalty card in store which once filled can be exchanged in for £20 cash!</p>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faVault} size="2x" style={{ color: 'red' }}/>
            </div>
            <h3 className="mt-3 font1">Safe With Us!</h3>
            <p className='fontdesc'>We keep all items in our secure and secretive safe in store to ensure that your items are always safe and in the exact condition you gave us them in</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default InfoSection;