import React from 'react';
import '../locationbanner.css';
import '../index.css'

const LocationBanner = () => {
    const apiKey = 'AIzaSyDQaFgEUBTwpL0-_p7BCJPfATipBQENrQA'; 
    const location = '88-90 Boothferry Rd Goole DN14 6AE';

    return (
        <section id="location-banner">
            <h2 className='fontdesc pd2' style={{ color: 'red', paddingTop: '2rem'}}>Our Location</h2>
            <div id="map-container">
                <iframe
                    id="map"
                    title="Shop Location"
                    width="300"
                    height="150"
                    style={{ border: 0 }}
                    loading="lazy"
                    allowFullScreen
                    src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${location}`}>
                </iframe>
            </div>
        </section>
    );
};

export default LocationBanner;
