
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faBagShopping, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faSterlingSign } from '@fortawesome/free-solid-svg-icons';


const InfoSection = () => {
  return (
    <Container className="text-center my-5">
      <Row style={{ marginTop: '8rem'}}>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faLock} size="2x" style={{ color: 'red'}}/>
            </div>
            <h3 className="mt-3 font1">Shop With Trust</h3>
            <p className='fontdesc'>We offer warranty on every item we sell online and in store </p>
            <a  className ='fontdesc' href="https://www.ebay.co.uk/sch/i.html?item=145860883947&rt=nc&_ssn=cash-it-in-goole">View our online store here</a>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faSterlingSign} size="2x" style={{ color: 'red' }}/>
            </div>
            <h3 className="mt-3 font1">Cash For Clutter</h3>
            <p className='fontdesc'>We buy in items of value, we also offer 28 day buy-backs for those prized items you want to get some cash for but don’t want to say goodbye to forever</p>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faLocationDot} size="2x" style={{ color: 'red' }}/>
            </div>
            <h3 className="mt-3 font1">Local</h3>
            <p className='fontdesc'>Ran by Goolies, for Goolies, we understand how important it is to make sure every customer leaves happy, and that we is that we ensure we do every single day</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default InfoSection;

