import React from 'react';
import '../index.css';
import '../custom.css';



const Titledesc = () => {
    return (
        <div className='text-center'>
            <p className='fontdesc fw-bold px-mob-3 mobiletitle' style={{marginTop: '4rem', color: 'red', fontSize: '1.8rem'}}>
            GET WHAT YOU WANT WITHOUT HAVING TO BLOW ALL YOUR CASH RIGHT AWAY! 
            </p>

            
            <p className='mobilefont fw-medium fontdesc text-center' style={{marginTop: '3rem', paddingLeft: '10%', paddingRight: '10%', fontSize: '1.3rem'}}>
            Come into store and ask to Lay-Away any item youd like and we will assist you, don’t be hesitant to ask questions, no question is a bad one!
            </p>
            
        </div>
    );
}

export default Titledesc;