
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faBagShopping, faLocationDot, faCalendarDays, faVault, faPercent} from '@fortawesome/free-solid-svg-icons';
import { faSterlingSign } from '@fortawesome/free-solid-svg-icons';



const InfoSection = () => {
  return (
    <Container className="text-center my-5">
      <Row style={{ marginTop: '8rem'}}>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faPercent} size="2x" style={{ color: 'red'}}/>
            </div>
            <h3 className="mt-3 font1">20% Deposit To Secure</h3>
            <p className='fontdesc'>You can secure any item in store for just 20% of the total price!</p>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faCalendarDays} size="2x" style={{ color: 'red' }}/>
            </div>
            <h3 className="mt-3 font1">Pay Off Every 2 Weeks </h3>
            <p className='fontdesc'>4 payments made every 2 weeks from the deposit is required to fully pay off your item </p>
          </div>
        </Col>
        <Col md={4} className="mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="icon">
              <FontAwesomeIcon icon={faLock} size="2x" style={{ color: 'red' }}/>
            </div>
            <h3 className="mt-3 font1">Winning Warranty</h3>
            <p className='fontdesc'>We still offer our guaranteed 30 day Warranty on Lay-away items, so if any issues arise you can always bring the item back to us!</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default InfoSection;